import React from "react"
import { graphql } from "gatsby"
import GatsImg from "gatsby-image"
import Headermenu from "../containers/RealestatePage1/HeaderMenuBS"
import Footer from "../containers/RealestatePage1/Footer"
import GlobalStyle from "../containers/RealestatePage1/Common/global-styles"

import Fonts from "../containers/RealestatePage1/Common/fonts"
import "../components/layout.css"
import SEO from "../components/seo"
import {
  contentWrapper,
  content,
  image,
} from "../containers/RealestatePage1/Common/markdownStyle.module.css"
import { Container, Row, Col } from "react-bootstrap"
import SideTestimonial from "../containers/RealestatePage1/Testimonials/SideTestimonial"

function Architecture({ data }) {
  console.log(data)
  const { html } = data.markdownRemark
  const { heading, img } = data.markdownRemark.frontmatter

  return (
    <>
      <Fonts />
      <GlobalStyle />
      <main>
        <SEO
          title="Architecture and Interior Design"
          description="Architectural design"
        />
        <Headermenu home={false} />
        <section className={contentWrapper}>
          <Container>
            <article id="architectureSection">
              <div className={content}>
                <GatsImg
                  fluid={img.childImageSharp.fluid}
                  style={{ maxHeight: 450 }}
                />
                <h2 style={{ textAlign: "start", marginTop: 20 }}>{heading}</h2>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </div>
            </article>
          </Container>
        </section>
      </main>

      <Footer />
    </>
  )
}
export const query = graphql`
  query ArchitectureQuery {
    markdownRemark(frontmatter: { title: { eq: "architecture" } }) {
      id
      html
      frontmatter {
        heading
        img {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Architecture
